<template>
  <!-- <ValidationObserver tag="form" ref="obs1" @submit.prevent="submit()"> -->
  <ValidationObserver
    tag="form"
    ref="didCampaignServiceRef"
    @submit.prevent="submit()"
    v-slot="{ invalid }"
  >
    <ValidationProvider v-slot="{ errors }" :rules="{ required: true }">
      <input
        type="hidden"
        v-model="didCampaignService.campaignResourceId"
        :error-messages="errors"
      />
    </ValidationProvider>
    <PreviewResource
      :selectedId="
        didCampaignService.campaignResourceId
          ? [didCampaignService.campaignResourceId]
          : []
      "
      :userId="didCampaignService.userId"
      :originId="didCampaignService.id"
      @setResource="setResource"
      :campaignType="'did'"
    />

    <DidCampaignServiceRegisterFooter :invalid="invalid" />
  </ValidationObserver>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import PreviewResource from '@/components/did/PreviewResource.vue';
import DidCampaignServiceRegisterFooter from './DidCampaignServiceRegisterFooter.vue';

import { CampaignResource } from '@/model/common';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    DidCampaignServiceRegisterFooter,
    PreviewResource
  },
  computed: {
    ...mapGetters({
      currentStep: 'didCampaignService/currentStep',
      dialog: 'didCampaignService/dialog',
      dialogLoading: 'didCampaignService/dialogLoading',
      isDialogTypeEdit: 'didCampaignService/isDialogTypeEdit',
      didCampaignService: 'didCampaignService/didCampaignService',
      campaignSteps: 'didCampaignService/campaignSteps',
      invalidDialog: 'didCampaignService/invalidDialog'
    })
  },
  watch: {
    dialog: function (newVal) {
      if (newVal) {
        this.init();
        this.$refs.didCampaignServiceRef.reset();
      }
    }
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions({
      setCurrentStep: 'didCampaignService/setCurrentStep'
    }),
    async submit() {
      const valid = await this.$refs.didCampaignServiceRef.validate();
      if (valid) {
        const step = this.currentStep;
        this.setCurrentStep(step + 1);
      }
    },
    setResource(resource) {
      this.didCampaignService.campaignResourceId = resource
        ? resource.id
        : null;
      this.didCampaignService.campaignResource = resource
        ? new CampaignResource(resource)
        : null;
    }
  }
};
</script>
